import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

// Importing images
import f1 from "../img/newsand/ANI-20231204163859 1.png";
import f2 from "../img/newsand/Delhi-HC-15 1.png";
import f3 from "../img/newsand/Fake-IT-raid 1.png";
import f4 from "../img/newsand/111111111111111111111 1.png";
import f5 from "../img/newsand/80167969 1.png";
import f6 from "../img/newsand/cyberfraud_d18abe 1.png";
import f7 from "../img/newsand/gavel-IE-4 (1) 1.png";

const LatestLegalDevelopments = () => {
    const legalItems = [
        {
            title: "Delhi Court Grants Interim Bail To Neeraj Bawana's Father For Surgery",
            image: f1,
            link: "https://indianexpress.com/article/cities/delhi/delhi-police-court-fake-i-t-raid-police-officers-haryana-gang-9591611/",
        },
        {
            title: "Autorickshaw Permit Scam: Delhi Court Grants Bail To Burari Transport Official",
            image: f2,
            link: "https://www.indianeconomicobserver.com//news/delhi-court-grants-interim-bail-to-neeraj-bawanas-father-for-surgery20231204230412/", // Add the correct URL here
        },
        {
            title: "Searches, Constables And A Haryana Gang: How Delhi Police Busted 'Fake' Income Tax Raid",
            image: f3,
            link: "https://indianexpress.com/article/cities/delhi/autorickshaw-permit-scam-delhi-court-bail-burari-transport-official-8961801/", // Add the correct URL here
        },
        {
            title: "Delhi Court Grants Bail To Alleged Mastermind Of Rs 91 Lakh Cyber Fraud",
            image: f4,
            link: "https://timesofindia.indiatimes.com/city/delhi/delhi-court-grants-bail-to-accused-in-91-lakh-fraud-case/articleshow/112935843.cms/", // Add the correct URL here
        },
        {
            title: "Delhi Court Grants Bail To Hotel Owner In Minor's Rape",
            image: f5,
            link: "https://indianexpress.com/article/cities/delhi/delhi-court-bail-cyber-fraud-9541018/", // Add the correct URL here
        },
        {
            title: "Court Grants Bail To Man Accused Of 91Lakh Fraud",
            image: f6,
            link: "https://timesofindia.indiatimes.com/city/delhi/court-grants-bail-to-hotel-owner-in-minors-rape/articleshow/80160731.cms", // Add the correct URL here
        },
        {
            title: "In Delhi Court, Daughter Claims Father Recorded In-Chamber Custody Proceeding, Judge Takes Note",
            image: f7,
            link: "https://indianexpress.com/article/cities/delhi/delhi-court-father-records-in-chamber-custody-proceedings-8056239/", // Add the correct URL here
        },
    ];

    return (
        <Container className="mt-4 letest-container-devlopment">
            <h2 className="text-center letest-development">LATEST LEGAL DEVELOPMENTS</h2>
            <Row>
                {legalItems.map((item, index) => (
                    <Col xs={12} sm={6} md={4} lg={4} className="mb-4" key={index}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                            <Card className="h-100 border-0">
                                <Card.Img variant="top" src={item.image} alt={item.title} />
                                <Card.Body>
                                    <Card.Text className="text-center">{item.title}</Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default LatestLegalDevelopments;
