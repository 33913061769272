import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

import logo from './img/Khapra law firm logo white 1.webp';
import { Helmet } from 'react-helmet';
import { NavLink, useLocation } from 'react-router-dom';

function NavbarHeader() {
    const [scrolled, setScrolled] = useState(false);



// start to tio side any pages 
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // const ScrollToTop = () => {

    //     useEffect(() => {
    //         window.scrollTo(0, 0);
    //     }, []);

    //     return null;
    // };


    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={logo} />
            </Helmet>

            <Container fluid className="p-0">
                <Navbar
                    expand="lg"
                    variant="dark"
                    className={`navbar-header position-fixed ${scrolled ? 'scrolled' : ''}`}
                >
                    <Container fluid className="p-0">
                        <Row className="align-items-center w-100">
                            <Col md={2} className='logo-colmn'>
                                <Navbar.Brand href="/">
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        style={{
                                            width: scrolled ? '40px' : '130px',
                                            height: scrolled ? '40px' : '124px',
                                            transition: 'width 0.3s, height 0.3s',

                                        }}

                                        className="responsive-logo"
                                    />
                                </Navbar.Brand>
                            </Col>
                            <Col md={10} className="text-end">
                                <Navbar.Toggle aria-controls="navbar-nav" />
                                <Navbar.Collapse id="navbar-nav">
                                    <Nav className="ms-auto navbar-gaping-custom">
                                        <NavLink to="/" className="nav-link">Home</NavLink>
                                        <NavLink to="/about" className="nav-link">About Us</NavLink>
                                        <NavLink to="/expertise" className="nav-link">Expertise</NavLink>
                                        <NavLink to="/our-team" className="nav-link">Our Team</NavLink>
                                        <NavLink to="/news-and-insights" className="nav-link">News and Insights</NavLink>
                                        <NavLink to="/careers" className="nav-link">Careers</NavLink>
                                        <NavLink to="/contact" className="nav-link">Contact Us</NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                                <hr
                                    className={`navbar-bottom-line ${scrolled ? 'd-none' : ''}`}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
            </Container>
        </>
    );
}

export default NavbarHeader;
