import React, { useEffect, useState } from 'react'
import '../components/css/contact.css';
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import ff from '../components/img/contact us page img 1.webp';
import loca from '../components/img/Screenshot 2024-10-17 115634 1.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import location from '../components/img/location.webp';
import add from '../components/img/material-symbols-light_meeting-room-outline.webp';
import con from '../components/img/cil_contact.webp';
import { TextField, Button } from '@mui/material'
import vector from '../components/img/Vector-3.webp';
import { motion } from "framer-motion";


const ContactUs = () => {

    const [hovered, setHovered] = useState(null);

    const circleVariants = (index) => ({
        initial: {
            cx: Math.random() * 400, // Random initial X position
            cy: Math.random() * 400, // Random initial Y position
        },
        animate: {
            cx: Math.random() * 400, // Random movement X
            cy: Math.random() * 400, // Random movement Y
            transition: {
                duration: 2,
                repeat: Infinity,
                repeatType: "mirror",
            },
        },
        hover: {
            cx: hovered === index ? Math.random() * 400 + 400 : undefined, // Move away
            cy: hovered === index ? Math.random() * 400 + 400 : undefined,
            transition: {
                duration: 0.5,
            },
        },
    });

    const handleMouseEnter = (index) => {
        setHovered(index);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };












    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        subject: '',
        description: '',
        preferredContact: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.fullName) newErrors.fullName = 'Full Name is required.';
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone Number is required.';
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Phone Number must be exactly 10 digits.';
        }
        if (!formData.emailAddress) {
            newErrors.emailAddress = 'Email Address is required.';
        } else if (
            !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.emailAddress)
        ) {
            newErrors.emailAddress = 'Invalid email format.';
        }
        if (!formData.subject) newErrors.subject = 'Subject is required.';
        if (!formData.description) newErrors.description = 'Description is required.';
        if (!formData.preferredContact) {
            newErrors.preferredContact = 'Preferred contact method is required.';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://khapralawfirm.in/server/contactForm.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            console.log('Response:', response); // Log the raw response

            if (response.ok) {
                const data = await response.json();
                console.log('Server Response:', data); // Log the parsed response

                if (data.status === 'success') {
                    toast.success(data.message, {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                    setFormData({
                        fullName: '',
                        phoneNumber: '',
                        emailAddress: '',
                        subject: '',
                        description: '',
                        preferredContact: '',
                    });
                } else {
                    toast.error(data.message || 'An unexpected error occurred on the server.', {
                        position: 'top-right',
                        autoClose: 5000,
                    });
                }
            } else {
                const errorData = await response.json();
                console.error('Server Error Response:', errorData); // Log error details
                toast.error(errorData.message || 'Failed to submit the form. Please try again.', {
                    position: 'top-right',
                    autoClose: 5000,
                });
            }
        } catch (error) {
            console.error('Fetch Error:', error); // Log error details
            toast.error(`An error occurred: ${error.message}. Please try again.`, {
                position: 'top-right',
                autoClose: 5000,
            });
        }
    };















    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#contactform') {
            const formSection = document.getElementById('contactformSection');
            if (formSection) {
                formSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);
    return (
        <>
            <section className="contact-container position-relative">
                {/* <img src={ff} alt="News and Insights" className="img-fluid w-100" /> */}
                <div className="contact-content position-absolute top-50 start-50 translate-middle text-center text-white px-3">
                    <h1 className="display-4 fw-bold">Get in Touch with Khapra Law Firm</h1>
                    <hr />
                    {/* <p className="lead">Home / Contact</p> */}
                </div>
            </section>

            <section className='adrees-contact-cards'>
                <Container className="py-4">
                    <Row className="g-4">
                        {/* Address Card */}
                        <Col md={4} sm={12} className="d-flex align-items-stretch">
                            <Card className="text-center contact-cards shadow-sm border-1 flex-grow-1">
                                <Card.Body className="d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-center">
                                        <img src={location} className="img-fluid" alt="Location" />
                                    </div>
                                    <Card.Title className="fw-bold">ADDRESS</Card.Title>
                                    <Card.Text className="mt-auto">
                                        Address: Khapra Law Firm, Chamber No. 335<br />
                                        Lawyers Chamber Block, Rohini Courts, <br />
                                        Rohini Delhi - 110085
                                        <br />
                                        <br />
                                        Office: Flat No. 36, Gulab Vihar Apartments, <br />
                                        Sector-9, Rohini, Delhi - 110085
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Contact Card */}
                        <Col md={4} sm={12} className="d-flex align-items-stretch">
                            <Card className="text-center contact-cards shadow-sm flex-grow-1">
                                <Card.Body className="d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-center">
                                        <img src={con} className="img-fluid" alt="Contact" />
                                    </div>
                                    <Card.Title className="fw-bold">CONTACT</Card.Title>
                                    <Card.Text className="mt-autoo">
                                        +91 9220442966 <br />
                                        +91 7895999966 <br />
                                        khapralawfirm@gmail.com
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Visiting Hours Card */}
                        <Col md={4} sm={12} className="d-flex align-items-stretch">
                            <Card className="text-center contact-cards shadow-sm flex-grow-1">
                                <Card.Body className="d-flex flex-column align-items-center">
                                    <div className="d-flex justify-content-center">
                                        <img src={add} className="img-fluid" alt="Visiting Hours" />
                                    </div>
                                    <Card.Title className="fw-bold">VISITING HOURS</Card.Title>
                                    <Card.Text className="mt-autoo">
                                        Office: 10:00 AM to 7:00 PM All Days<br />
                                        <br />
                                        Chamber: 10:00 AM to 4:30 PM <br />
                                        Except for 2nd Saturday and Sunday
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>




            {/* <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
                <motion.svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 800 800"
                    style={{ background: "#f0f0f0" }}
                >
                    {[...Array(5)].map((_, index) => (
                        <motion.circle
                            key={index}
                            r="20"
                            fill="blue"
                            stroke="black"
                            strokeWidth="2"
                            variants={circleVariants(index)}
                            initial="initial"
                            animate="animate"
                            whileHover="hover"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        />
                    ))}
                </motion.svg>
            </div> */}




            <section id='contactformSection'>
                <Container
                    className="py-5 online-container-contact animate__animated"

                >
                    <h2 className="text-center mb-4">ONLINE INQUIRY FORM</h2>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col md={6} sm={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Name *"
                                    placeholder="Enter your full name"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    error={!!errors.fullName}
                                    helperText={errors.fullName}
                                />
                            </Col>
                            <Col md={6} sm={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Phone Number *"
                                    placeholder="Enter your phone number"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter your email address"
                                    name="emailAddress"
                                    value={formData.emailAddress}
                                    onChange={handleChange}
                                    error={!!errors.emailAddress}
                                    helperText={errors.emailAddress}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter subject of inquiry"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    error={!!errors.subject}
                                    helperText={errors.subject}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    placeholder="Provide a brief description of your legal issue"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    error={!!errors.description}
                                    helperText={errors.description}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter preferred method of contact"
                                    name="preferredContact"
                                    value={formData.preferredContact}
                                    onChange={handleChange}
                                    error={!!errors.preferredContact}
                                    helperText={errors.preferredContact}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="text-end">
                                <Button
                                    variant="contained"
                                    className="submit-btn-contact"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </section>




            <ToastContainer />



            <section className='location-section'>
                <img
                    src={loca}
                    alt="Location"
                    className="img-fluid w-100 rounded"
                />
            </section>

        </>
    )
}

export default ContactUs
