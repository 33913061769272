import React from 'react'
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


import ff from '../components/img/Group 7.webp';
const teamMembers = [
  { role: "CHAIRMAN", name: "DHARAMBIR SINGH KHAPRA" },
  { role: "SENIOR PARTNER", name: "PRAVESH DABAS" },
  { role: "SENIOR PARTNER", name: "NITESH KHAPRA" },
  { role: "SENIOR ATTORNEY", name: "MOHIT KUMAR" },
  { role: "SENIOR ATTORNEY", name: "ABHISHEK YADAV" },
  { role: "SENIOR ATTORNEY", name: "NITIN GABA" },

  { role: "ATTORNEY", name: "	BHUMIKA GABA" },
  { role: "ATTORNEY", name: "		KUNAL BHARDAWAJ" },
  { role: "ATTORNEY", name: "		KUNAL BHARDAWAJ" },
  { role: "ATTORNEY", name: "MALTI" },
  { role: "ATTORNEY", name: "HITESH KHANDELWAL" },
  { role: "ATTORNEY", name: "KUNAL VASHISHTH" },
  { role: "ATTORNEY", name: "KUNAL VASHISHTH" },






  { role: "SUPPORT STAFF", name: "GAURAV CHAHAL" },
  { role: "SUPPORT STAFF", name: "NISHANT DAROLIA" },
  { role: "SUPPORT STAFF", name: "NIKET DAHIYA" },
  { role: "SUPPORT STAFF", name: "NISHA YADAV" },
  { role: "SUPPORT STAFF", name: "RIYA" },
  { role: "SUPPORT STAFF", name: "NIKUNJ TRIPATHI" },
  { role: "SUPPORT STAFF", name: "LAKSHAY" },








];
const OurTeam = () => {
  return (
    <>
      <section className='team-section-container position-relative'>
        <img src={ff} className='img-fluid w-100' />
        <div className="contact-content our-team-content-first position-absolute top-50 start-50 translate-middle text-center text-white px-3">
          <h1 className="display-4 fw-bold">MEET OUR TEAM</h1>
          <hr />
          {/* <p className="lead">Home / Our Team</p> */}
        </div>
      </section>

      <section className='team cards-section'>
        <Container className="py-5">
          <Row className="g-4">
            {teamMembers.map((member, index) => (
              <Col key={index} md={4} sm={6} xs={12} className="d-flex justify-content-center">
                <Card
                  className="text-center border border-secondary slider-cards-our-team"
                  style={{ width: "18rem", height: "15rem" }}
                >
                  <Card.Body className="d-flex flex-column justify-content-end">
                    <Card.Title className="fw-bold">{member.role}</Card.Title>
                    <Card.Text>{member.name}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

            ))}
          </Row>
        </Container>
      </section>

    </>
  )
}

export default OurTeam
