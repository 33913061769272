import React from 'react';
import { useParams } from 'react-router-dom';
import '../components/css/lawdetails.css';
import cc from '../components/img/details/9 1.png';
import cl from '../components/img/details/10 1.png';
import fl from '../components/img/details/11 1.png';
import cor from '../components/img/details/12 1.png';
import ill from '../components/img/details/13 1.png';
import cons from '../components/img/details/14 1.png';
import real from '../components/img/details/15 1.png';
import cy from '../components/img/details/16 1.png';
import la from '../components/img/details/17 1.png';
import arb from '../components/img/details/17 1.png';



import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

const CriminalLawDetails = () => {
    const { id } = useParams();

    const lawCategories = [
        // { id: 1, title: "Civil Law", description: "Whether it’s a personal dispute or a commercial issue, we handle civil litigation and settlements with precision.", image: cl },

        {
            id: 1,
            title: "Civil Law Services",
            description: "Comprehensive legal services focused on resolving civil disputes through negotiation, litigation, or arbitration, ensuring fair outcomes for clients.",
            image: cl, // Replace 'cl' with the appropriate image variable or path
            services: [
                {
                    category: "Contract Disputes",
                    details: [
                        "Breach of contract claims.",
                        "Disputes involving business agreements, leases, or service contracts.",
                        "Issues related to non-performance or misrepresentation in contracts."
                    ]
                },
                {
                    category: "Property Disputes",
                    details: [
                        "Ownership disputes over land or real estate.",
                        "Boundary or easement disagreements.",
                        "Adverse possession claims.",
                        "Encroachment or unlawful occupation."
                    ]
                },
                {
                    category: "Landlord-Tenant Disputes",
                    details: [
                        "Non-payment of rent or lease violations.",
                        "Illegal eviction or tenancy rights disputes.",
                        "Repairs and maintenance disagreements.",
                        "Security deposit recovery."
                    ]
                },
                {
                    category: "Debt Recovery",
                    details: [
                        "Recovery suits filed by creditors.",
                        "Disputes over loan agreements or guarantees.",
                        "Claims related to unpaid invoices or dues."
                    ]
                },
                {
                    category: "Employment Disputes",
                    details: [
                        "Wrongful termination or unfair dismissal claims.",
                        "Workplace harassment or discrimination cases.",
                        "Non-payment of wages or breach of employment contracts."
                    ]
                },
                {
                    category: "Defamation Cases",
                    details: [
                        "Lawsuits related to libel (written defamation) or slander (spoken defamation).",
                        "Claims for reputational damage."
                    ]
                },
                {
                    category: "Trusts and Estate Disputes",
                    details: [
                        "Disputes over the validity of wills or trusts.",
                        "Claims related to inheritance or succession.",
                        "Allegations of mismanagement by executors or trustees."
                    ]
                },
                {
                    category: "Business and Commercial Disputes",
                    details: [
                        "Shareholder or partnership disputes.",
                        "Breach of fiduciary duties.",
                        "Disputes involving mergers, acquisitions, or joint ventures."
                    ]
                },
                {
                    category: "Intellectual Property (IP) Disputes",
                    details: [
                        "Copyright, trademark, or patent infringement cases.",
                        "Disputes over IP ownership or licensing agreements."
                    ]
                },
                {
                    category: "Environmental and Land Use Cases",
                    details: [
                        "Cases involving pollution, environmental damage, or zoning violations.",
                        "Disputes over land acquisition or environmental compliance."
                    ]
                },
                {
                    category: "Personal Injury Claims",
                    details: [
                        "Compensation for injuries caused by negligence (e.g., car accidents, slip and fall incidents).",
                        "Medical malpractice cases.",
                        "Workplace injury claims."
                    ]
                },
                {
                    category: "Tort Claims",
                    details: [
                        "Cases involving negligence, nuisance, or trespass.",
                        "Claims for emotional distress or loss of consortium."
                    ]
                },
                {
                    category: "Administrative and Regulatory Disputes",
                    details: [
                        "Challenging government actions, policies, or decisions.",
                        "Disputes involving licensing, permits, or regulatory compliance."
                    ]
                },
                {
                    category: "Class Action Lawsuits",
                    details: [
                        "Cases involving multiple plaintiffs with similar claims against a single defendant (e.g., product liability or fraud)."
                    ]
                },
                {
                    category: "Financial and Investment Disputes",
                    details: [
                        "Breach of fiduciary duty by financial advisors or brokers.",
                        "Disputes over investments or securities fraud."
                    ]
                }
            ]
        }
        ,

        // { id: 2, title: "Family Law", description: "Our family law services cover divorce, custody, alimony, and more, helping clients navigate emotionally charged situations.", image: fl },
        {
            id: 2,
            title: "Family Law",
            description: "Comprehensive legal support for family-related matters, including divorce, child custody, alimony, inheritance, and more.",
            image: fl, // Replace 'family_law' with the appropriate image variable or path
            services: [
                {
                    category: "Divorce and Separation",
                    details: [
                        "Filing for divorce or annulment.",
                        "Contested and uncontested divorce cases.",
                        "Legal separation agreements."
                    ]
                },
                {
                    category: "Child Custody and Visitation",
                    details: [
                        "Disputes over physical and legal custody of children.",
                        "Establishing or modifying visitation rights.",
                        "Cases involving relocation of custodial parents."
                    ]
                },
                {
                    category: "Alimony and Maintenance",
                    details: [
                        "Claims for spousal support or alimony.",
                        "Modification or enforcement of maintenance orders."
                    ]
                },
                {
                    category: "Property Division",
                    details: [
                        "Division of marital assets and liabilities.",
                        "Disputes over jointly owned property.",
                        "Claims related to dowry and matrimonial assets."
                    ]
                },
                {
                    category: "Domestic Violence",
                    details: [
                        "Filing or defending domestic violence cases.",
                        "Obtaining protection orders or restraining orders.",
                        "Counseling and legal remedies for victims."
                    ]
                },
                {
                    category: "Child Support",
                    details: [
                        "Determining or modifying child support payments.",
                        "Enforcement of child support orders."
                    ]
                },
                {
                    category: "Guardianship",
                    details: [
                        "Disputes over guardianship of minors or incapacitated adults.",
                        "Appointment or removal of legal guardians."
                    ]
                },
                {
                    category: "Adoption",
                    details: [
                        "Legal processes for domestic or international adoption.",
                        "Disputes involving the biological parents' consent.",
                        "Challenging the legality of an adoption."
                    ]
                },
                {
                    category: "Paternity Disputes",
                    details: [
                        "Establishing or disputing paternity through legal proceedings.",
                        "Rights and obligations of biological fathers."
                    ]
                },
                {
                    category: "Inheritance and Succession",
                    details: [
                        "Disputes over division of ancestral or inherited property.",
                        "Contested wills or probate cases.",
                        "Claims by legal heirs under succession laws."
                    ]
                },
                {
                    category: "Live-In Relationships",
                    details: [
                        "Disputes involving rights of partners in live-in relationships.",
                        "Maintenance claims and property division."
                    ]
                },
                {
                    category: "Marriage-Related Disputes",
                    details: [
                        "Nullity of marriage on grounds such as fraud, coercion, or mental incapacity.",
                        "Disputes related to bigamy or polygamy."
                    ]
                },
                {
                    category: "Surrogacy and Reproductive Rights",
                    details: [
                        "Legal disputes involving surrogacy agreements.",
                        "Custody disputes over children born via surrogacy."
                    ]
                },
                {
                    category: "Elder Care and Parental Maintenance",
                    details: [
                        "Disputes over caregiving responsibilities for elderly family members.",
                        "Legal actions under laws like the Maintenance and Welfare of Parents and Senior Citizens Act (India)."
                    ]
                },
                {
                    category: "Cultural and Religious Disputes",
                    details: [
                        "Interfaith or intercaste marriage issues.",
                        "Custody or inheritance disputes influenced by religious or cultural practices."
                    ]
                },
                {
                    category: "Family Business Disputes",
                    details: [
                        "Conflicts over management, ownership, or division of family businesses.",
                        "Disputes over succession in family-run enterprises."
                    ]
                }
            ]
        }
        ,

        // { id: 3, title: "Corporate Law", description: "Our corporate law services include business formation, compliance, and dispute resolution.", image: cor },
        {
            id: 3,
            title: "Corporate Law",
            description: "Our family law services cover divorce, custody, alimony, and more, helping clients navigate emotionally charged situations.",
            image: cor,
            // category: "CORPORATE",
            services: [
                {
                    category: "Business Formation and Structuring",
                    details: [
                        "Advising on the appropriate business structure (e.g., private limited, LLP, sole proprietorship).",
                        "Assisting with company registration and incorporation.",
                        "Drafting Memorandum and Articles of Association (MOA/AOA).",
                        "Guidance on compliance with start-up or MSME regulations."
                    ]
                },
                {
                    category: "Corporate Compliance and Governance",
                    details: [
                        "Advising on corporate governance best practices.",
                        "Assistance with compliance under the Companies Act or equivalent regulations.",
                        "Drafting board resolutions, minutes, and other statutory documents.",
                        "Filing annual returns, financial statements, and other statutory forms."
                    ]
                },
                {
                    category: "Mergers and Acquisitions (M&A)",
                    details: [
                        "Legal due diligence for mergers, acquisitions, or joint ventures.",
                        "Structuring and negotiating transaction agreements.",
                        "Regulatory compliance, including approvals from government bodies.",
                        "Handling post-merger integration legalities."
                    ]
                },
                {
                    category: "Contract Drafting and Negotiation",
                    details: [
                        "Drafting and reviewing business contracts, including vendor agreements, supply contracts, and service agreements.",
                        "Negotiating terms in commercial agreements.",
                        "Creating standard operating agreements (SOAs) and NDAs."
                    ]
                },
                {
                    category: "Employment and Labor Law Compliance",
                    details: [
                        "Advising on employment contracts, HR policies, and employee handbooks.",
                        "Ensuring compliance with labor laws, including minimum wage, working hours, and termination policies.",
                        "Representation in labor disputes or employee grievances."
                    ]
                },
                {
                    category: "Intellectual Property (IP) Management",
                    details: [
                        "Assisting with trademark, copyright, and patent registration.",
                        "Handling IP infringement claims.",
                        "Drafting licensing and assignment agreements for IP assets."
                    ]
                },
                {
                    category: "Banking and Finance",
                    details: [
                        "Drafting loan agreements, security documents, and guarantees.",
                        "Advising on project finance, debt restructuring, and syndicated loans.",
                        "Ensuring compliance with banking regulations."
                    ]
                },
                {
                    category: "Corporate Disputes and Litigation",
                    details: [
                        "Representing clients in shareholder disputes.",
                        "Handling breach of contract cases.",
                        "Advising on director liability and fiduciary duties."
                    ]
                },
                {
                    category: "Insolvency and Bankruptcy",
                    details: [
                        "Advising on corporate insolvency under relevant laws.",
                        "Representation in insolvency resolution proceedings.",
                        "Assisting creditors, debtors, or resolution professionals."
                    ]
                },
                {
                    category: "Regulatory and Compliance Matters",
                    details: [
                        "Ensuring adherence to sector-specific regulations (e.g., telecom, pharmaceuticals, or fintech).",
                        "Assistance with obtaining licenses, permits, and approvals.",
                        "Advising on environmental and tax regulations."
                    ]
                },
                {
                    category: "Taxation and Financial Structuring",
                    details: [
                        "Advising on corporate tax planning and structuring.",
                        "Representation in tax disputes or audits.",
                        "Ensuring compliance with GST and other indirect taxes."
                    ]
                },
                {
                    category: "Private Equity and Venture Capital",
                    details: [
                        "Drafting shareholder agreements and investment term sheets.",
                        "Structuring venture capital investments and exits.",
                        "Advising start-ups on fundraising strategies."
                    ]
                },
                {
                    category: "Data Privacy and Cybersecurity",
                    details: [
                        "Advising on data protection laws like GDPR or Indian IT Act provisions.",
                        "Drafting privacy policies and data-sharing agreements.",
                        "Representing companies in data breach disputes."
                    ]
                },
                {
                    category: "Cross-Border Transactions",
                    details: [
                        "Advising on foreign direct investment (FDI) policies.",
                        "Structuring international joint ventures or partnerships.",
                        "Compliance with exchange control laws (e.g., FEMA in India)."
                    ]
                },
                {
                    category: "Business Succession Planning",
                    details: [
                        "Structuring family-owned businesses for succession.",
                        "Drafting shareholder or partnership agreements for transition.",
                        "Advising on tax-efficient succession strategies."
                    ]
                },
                {
                    category: "Public Offerings and Securities",
                    details: [
                        "Advising on Initial Public Offerings (IPO) or private placements.",
                        "Ensuring compliance with securities laws and stock exchange regulations.",
                        "Handling insider trading, market manipulation, and related issues."
                    ]
                },
                {
                    category: "Alternative Dispute Resolution (ADR)",
                    details: [
                        "Mediation or arbitration for corporate disputes.",
                        "Representation in domestic or international arbitration proceedings."
                    ]
                },
                {
                    category: "Franchising and Licensing",
                    details: [
                        "Drafting franchise agreements and licensing documents.",
                        "Advising on franchising regulations and compliance."
                    ]
                }
            ]
        }
        ,
        { id: 4, title: "Intellectual Property Right Laws", description: "We assist clients in protecting their intellectual property, ensuring innovations and creations are safeguarded.", image: ill },
        // { id: 5, title: "Consumer Law", description: "We defend consumer rights against unfair trade practices, ensuring clients receive justice.", image: cons },
        {
            id: 5,
            title: "Consumer Law Services",
            description: "Expert legal support to protect consumer rights and resolve disputes involving goods, services, and fair practices.",
            image: cl, // Replace 'cl' with the appropriate image variable or path
            services: [
                {
                    category: "Consumer Dispute Resolution",
                    details: [
                        "Filing or defending complaints before consumer forums, including District Consumer Disputes Redressal Commission (DCDRC), State Consumer Disputes Redressal Commission (SCDRC), and National Consumer Disputes Redressal Commission (NCDRC).",
                        "Representation in appeals and revisions before higher authorities."
                    ]
                },
                {
                    category: "Defective Products and Services",
                    details: [
                        "Legal action for defective goods or deficient services.",
                        "Filing claims for compensation due to manufacturing defects or substandard services.",
                        "Advisory for businesses on product liability issues."
                    ]
                },
                {
                    category: "Misleading Advertisements",
                    details: [
                        "Legal remedies against false or deceptive advertisements.",
                        "Representation in cases involving fraudulent claims by sellers or manufacturers."
                    ]
                },
                {
                    category: "Unfair Trade Practices",
                    details: [
                        "Filing complaints for practices like overpricing, black marketing, or misleading branding.",
                        "Representation in cases involving unfair terms in agreements or contracts."
                    ]
                },
                {
                    category: "E-Commerce and Online Consumer Rights",
                    details: [
                        "Resolving disputes related to online purchases.",
                        "Filing complaints against delayed deliveries, fake products, or hidden charges.",
                        "Advisory on refund or return policies of online platforms."
                    ]
                },
                {
                    category: "Warranty and Guarantee Issues",
                    details: [
                        "Claims for breach of warranty or guarantee on goods and services.",
                        "Disputes involving extended warranties or repair services."
                    ]
                },
                {
                    category: "Insurance Claims",
                    details: [
                        "Assistance with rejection or delayed settlement of insurance claims.",
                        "Filing complaints for inadequate or unfair claim processing.",
                        "Representation in cases of misrepresentation by insurance companies."
                    ]
                },
                {
                    category: "Real Estate and Housing Complaints",
                    details: [
                        "Legal action for delayed possession or construction defects.",
                        "Claims for refund or compensation under RERA (Real Estate Regulation and Development Act).",
                        "Representation in disputes with builders or housing societies."
                    ]
                },
                {
                    category: "Medical Negligence",
                    details: [
                        "Filing complaints for substandard treatment or errors by medical professionals.",
                        "Claims for compensation in cases of misdiagnosis, surgical errors, or inadequate care."
                    ]
                },
                {
                    category: "Telecom and Utility Services",
                    details: [
                        "Handling disputes with telecom companies over billing, network issues, or service interruptions.",
                        "Resolving complaints involving water, electricity, or gas utilities."
                    ]
                },
                {
                    category: "Travel and Hospitality Disputes",
                    details: [
                        "Claims against travel agents, airlines, or hotels for deficiencies in services.",
                        "Legal action for cancellation charges, unfulfilled promises, or lost luggage."
                    ]
                },
                {
                    category: "Banking and Financial Services",
                    details: [
                        "Complaints related to unfair charges, unauthorized transactions, or fraudulent practices.",
                        "Resolving disputes involving credit cards, loans, or investment products.",
                        "Claims for misrepresentation by banks or financial advisors."
                    ]
                },
                {
                    category: "Fraud and Identity Theft",
                    details: [
                        "Legal remedies for consumers affected by identity theft or online fraud.",
                        "Assistance in recovering losses from fraudulent transactions."
                    ]
                },
                {
                    category: "Food Safety and Quality",
                    details: [
                        "Filing complaints for adulterated or substandard food products.",
                        "Representation in cases involving violations of food safety standards."
                    ]
                },
                {
                    category: "Education Services",
                    details: [
                        "Claims against educational institutions for deficient services.",
                        "Disputes over fee refunds or course quality."
                    ]
                },
                {
                    category: "Advisory for Businesses",
                    details: [
                        "Compliance with consumer protection laws.",
                        "Drafting terms and conditions, refund policies, and disclaimers.",
                        "Guidance on fair advertising and marketing practices."
                    ]
                },
                {
                    category: "Compensation Claims",
                    details: [
                        "Filing for compensation in cases involving financial loss, mental agony, or physical harm.",
                        "Legal remedies for non-compliance with consumer rights."
                    ]
                },
                {
                    category: "Class Action Lawsuits",
                    details: [
                        "Representing groups of consumers in collective claims against businesses or service providers."
                    ]
                }
            ]
        },

        // { id: 6, title: "Real Estate Laws", description: "From property disputes to real estate transactions, we provide comprehensive legal assistance.", image: real },
        {
            id: 6,
            title: "Real Estate Law",
            description: "From property disputes to real estate transactions, we provide comprehensive legal assistance.",
            image: real,
            services: [
                {
                    category: "Property Transactions",
                    details: [
                        "Sale and Purchase Agreements: Drafting, reviewing, and negotiating contracts for buying or selling property.",
                        "Title Verification: Ensuring the property has a clear title and no encumbrances.",
                        "Registration of Property: Assistance with the registration process and compliance with local laws.",
                        "Lease and Rental Agreements: Drafting and reviewing agreements for residential, commercial, or industrial properties."
                    ]
                },
                {
                    category: "Real Estate Development",
                    details: [
                        "Advising developers on land acquisition, zoning laws, and project approvals.",
                        "Assisting with construction contracts and joint development agreements.",
                        "Handling compliance with environmental and municipal regulations."
                    ]
                },
                {
                    category: "Dispute Resolution",
                    details: [
                        "Property Disputes: Resolving disputes over ownership, boundaries, or rights.",
                        "Landlord-Tenant Disputes: Handling eviction, rent disputes, or maintenance issues.",
                        "Builder-Buyer Disputes: Addressing delays, quality issues, or non-delivery of promised amenities.",
                        "Partition Suits: Assisting in dividing property among co-owners."
                    ]
                },
                {
                    category: "Mortgage and Finance",
                    details: [
                        "Drafting and reviewing loan agreements and mortgage deeds.",
                        "Advising on foreclosure and recovery of property in case of default.",
                        "Assisting with refinancing or restructuring of real estate loans."
                    ]
                },
                {
                    category: "Land Use and Zoning",
                    details: [
                        "Advising on zoning regulations and permissible use of land.",
                        "Representation in cases involving zoning violations or disputes.",
                        "Assisting with obtaining permits for construction or renovation."
                    ]
                },
                {
                    category: "Real Estate Investment",
                    details: [
                        "Advising on real estate investment trusts (REITs) and other investment vehicles.",
                        "Guidance on buying and managing income-generating properties.",
                        "Structuring joint ventures or partnerships for real estate investments."
                    ]
                },
                {
                    category: "Regulatory Compliance",
                    details: [
                        "Ensuring compliance with local, state, and national property laws.",
                        "Assistance with Real Estate (Regulation and Development) Act (RERA) compliance.",
                        "Handling approvals from government bodies for property transactions."
                    ]
                },
                {
                    category: "Succession and Estate Planning",
                    details: [
                        "Advising on inheritance and transfer of property through wills or trusts.",
                        "Assisting with probate and succession certificate processes.",
                        "Handling disputes among heirs over property distribution."
                    ]
                },
                {
                    category: "Environmental and Land Rights",
                    details: [
                        "Advising on compliance with environmental regulations affecting real estate.",
                        "Representing clients in cases involving forest land, tribal rights, or ecological concerns.",
                        "Handling disputes related to encroachments or illegal constructions."
                    ]
                },
                {
                    category: "Commercial Real Estate",
                    details: [
                        "Assisting businesses in leasing or buying commercial spaces.",
                        "Drafting contracts for office, retail, or industrial properties.",
                        "Negotiating terms for long-term leases or build-to-suit arrangements."
                    ]
                },
                {
                    category: "Eminent Domain and Compensation",
                    details: [
                        "Representing landowners in cases of government acquisition of land.",
                        "Negotiating fair compensation for compulsory acquisitions.",
                        "Challenging acquisitions in court if needed."
                    ]
                },
                {
                    category: "Taxation",
                    details: [
                        "Advising on property taxes, stamp duty, and capital gains tax implications.",
                        "Structuring transactions to minimize tax liabilities.",
                        "Representing clients in disputes with tax authorities."
                    ]
                },
                {
                    category: "Real Estate Arbitration and Mediation",
                    details: [
                        "Resolving property disputes through alternative dispute resolution methods.",
                        "Acting as arbitrators or mediators in real estate conflicts."
                    ]
                },
                {
                    category: "Construction Law",
                    details: [
                        "Drafting and reviewing construction contracts and subcontracts.",
                        "Advising on project delays, cost overruns, or breach of contract.",
                        "Handling disputes related to construction defects or warranties."
                    ]
                },
                {
                    category: "Foreign Investment in Real Estate",
                    details: [
                        "Advising foreign investors on property acquisition laws.",
                        "Assisting with regulatory approvals for foreign direct investment (FDI) in real estate.",
                        "Structuring transactions to comply with local laws."
                    ]
                }
            ]
        }
        ,

        // { id: 7, title: "Cyber Laws", description: "In today’s digital age, we offer expert legal services for cybersecurity, data privacy, and online crimes.", image: cy },
        {
            id: 7,
            title: "Cyber Laws",
            description: "Expert legal services for navigating the complexities of cybercrime, data protection, intellectual property rights, e-commerce, and online transactions.",
            image: cy, // Replace 'cyber_law' with the appropriate image variable or path
            services: [
                {
                    category: "Cybercrime Complaints and Litigation",
                    details: [
                        "Filing complaints for cybercrimes, including hacking, phishing, and identity theft.",
                        "Representation in cases involving cyberbullying or online harassment.",
                        "Defamation on social media or digital platforms.",
                        "Fraudulent online transactions.",
                        "Liaising with cybercrime investigation cells and law enforcement agencies."
                    ]
                },
                {
                    category: "Data Protection and Privacy",
                    details: [
                        "Advising on compliance with data protection laws like GDPR (General Data Protection Regulation) or IT Act provisions in India.",
                        "Drafting and reviewing privacy policies and terms of use.",
                        "Legal assistance in cases of data breaches or unauthorized data sharing."
                    ]
                },
                {
                    category: "Intellectual Property Rights in Cyberspace",
                    details: [
                        "Handling copyright infringement cases related to digital content.",
                        "Filing claims for misuse of trademarks, domain names, or other IP assets online.",
                        "Advising on software piracy and licensing agreements."
                    ]
                },
                {
                    category: "E-Commerce and Online Transactions",
                    details: [
                        "Resolving disputes arising from online shopping, payment failures, or non-delivery of goods.",
                        "Drafting terms and conditions for e-commerce platforms.",
                        "Legal remedies for misleading advertisements or unfair trade practices online."
                    ]
                },
                {
                    category: "Cybersecurity Compliance",
                    details: [
                        "Advising businesses on cybersecurity frameworks and legal obligations.",
                        "Assisting with certifications and compliance audits under cybersecurity laws.",
                        "Representing companies in cases of non-compliance or breaches."
                    ]
                },
                {
                    category: "Online Contract Management",
                    details: [
                        "Drafting, reviewing, and enforcing electronic contracts.",
                        "Advising on the validity and enforceability of digital signatures.",
                        "Resolving disputes related to online agreements."
                    ]
                },
                {
                    category: "Social Media and Content Regulation",
                    details: [
                        "Handling cases involving defamatory, obscene, or offensive content on social media.",
                        "Filing complaints for fake profiles, impersonation, or unauthorized use of personal information.",
                        "Advising influencers and digital content creators on compliance with laws."
                    ]
                },
                {
                    category: "Cyber Law Advisory for Businesses",
                    details: [
                        "Drafting IT and cybersecurity policies for organizations.",
                        "Advising on safe practices for handling customer and employee data.",
                        "Conducting training sessions on cyber law compliance for corporate staff."
                    ]
                },
                {
                    category: "Cyber Defamation",
                    details: [
                        "Legal remedies for online defamation, slander, or libel.",
                        "Filing take-down notices or seeking injunctions against defamatory content."
                    ]
                },
                {
                    category: "Fraud and Financial Crimes",
                    details: [
                        "Handling cases involving credit card fraud, online scams, or Ponzi schemes.",
                        "Advising on legal remedies for unauthorized access to bank accounts or payment gateways."
                    ]
                },
                {
                    category: "Digital Evidence Management",
                    details: [
                        "Assisting in the collection, preservation, and presentation of digital evidence in court.",
                        "Liaising with forensic experts for recovering or authenticating electronic data."
                    ]
                },
                {
                    category: "Website and Domain Name Disputes",
                    details: [
                        "Handling disputes over domain name registration or ownership.",
                        "Advising on cyber-squatting and related remedies.",
                        "Representing clients before arbitration forums like WIPO."
                    ]
                },
                {
                    category: "Cyber Extortion and Ransomware",
                    details: [
                        "Legal remedies for victims of cyber extortion or ransomware attacks.",
                        "Advising on handling such incidents, including reporting and negotiations."
                    ]
                },
                {
                    category: "Online Gaming and Gambling Laws",
                    details: [
                        "Advising on the legality and compliance of online gaming platforms.",
                        "Resolving disputes related to gaming transactions or betting."
                    ]
                },
                {
                    category: "Cyber Ethics and Awareness",
                    details: [
                        "Conducting awareness programs on cyber ethics and legal rights.",
                        "Advising schools, colleges, and organizations on cyber safety."
                    ]
                },
                {
                    category: "Cyber Law for Startups",
                    details: [
                        "Assisting startups in drafting IT agreements, user policies, and compliance frameworks.",
                        "Advising tech companies on emerging cyber law trends."
                    ]
                },
                {
                    category: "Cross-Border Cyber Issues",
                    details: [
                        "Advising on international data transfer and jurisdictional disputes.",
                        "Representing clients in cross-border cybercrime cases."
                    ]
                }
            ]
        }
        ,
        // { id: 8, title: "Labour and Industrial Laws", description: "We provide solutions for employment disputes, worker’s rights, and industrial law cases.", image: la },
        {
            id: 8,
            title: "Labour and Industrial Laws",
            description: "Labour and Industrial Laws cover a wide range of services related to employment, workplace disputes, compliance with statutory obligations, and employee welfare.",
            image: la,
            services: [
                {
                    category: "Employment Contracts and Agreements",
                    details: [
                        "Drafting and reviewing employment contracts for employees, consultants, and contractors.",
                        "Advising on non-compete, confidentiality, and non-disclosure agreements.",
                        "Resolving disputes arising from employment contracts."
                    ]
                },
                {
                    category: "Compliance with Labour Laws",
                    subcategories: [
                        {
                            title: "Statutory Compliance",
                            details: [
                                "Ensuring compliance with laws such as:",
                                "Minimum Wages Act.",
                                "Payment of Wages Act.",
                                "Employees’ Provident Fund and Miscellaneous Provisions Act.",
                                "Payment of Bonus Act.",
                                "Payment of Gratuity Act.",
                                "Factories Act.",
                                "Shops and Establishments Act."
                            ]
                        },
                        {
                            title: "Labour Law Audits",
                            details: [
                                "Conducting labour law audits to ensure adherence to statutory obligations.",
                                "Assisting with filing statutory returns and maintaining records."
                            ]
                        }
                    ]
                },
                {
                    category: "Industrial Disputes and Relations",
                    details: [
                        "Advising on matters under the Industrial Disputes Act, including:",
                        "Layoffs, retrenchment, and closures.",
                        "Strikes, lockouts, and industrial unrest.",
                        "Representation before Labour Courts, Industrial Tribunals, and Arbitrators.",
                        "Drafting and negotiating collective bargaining agreements."
                    ]
                },
                {
                    category: "Employee Benefits and Welfare",
                    details: [
                        "Advising on employee welfare schemes and statutory benefits, such as:",
                        "Provident fund.",
                        "Gratuity.",
                        "Employees' State Insurance (ESI).",
                        "Leave policies.",
                        "Resolving disputes regarding unpaid benefits or wrongful denial of claims."
                    ]
                },
                {
                    category: "Workplace Harassment and Discrimination",
                    details: [
                        "Assisting in compliance with the Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act.",
                        "Advising on workplace diversity and anti-discrimination policies.",
                        "Representation in cases involving harassment or workplace discrimination."
                    ]
                },
                {
                    category: "Health and Safety Compliance",
                    details: [
                        "Advising on occupational health and safety standards under the Factories Act or equivalent laws.",
                        "Drafting safety policies and conducting workplace risk assessments.",
                        "Representation in cases involving workplace accidents or negligence claims."
                    ]
                },
                {
                    category: "Termination and Retrenchment",
                    details: [
                        "Advising on lawful termination of employees.",
                        "Drafting termination letters and settlement agreements.",
                        "Representation in disputes over wrongful termination or non-payment of dues."
                    ]
                },
                {
                    category: "Trade Union Matters",
                    details: [
                        "Advising on the formation and registration of trade unions.",
                        "Representation in disputes involving trade union activities or rights.",
                        "Assisting in collective bargaining and negotiation with trade unions."
                    ]
                },
                {
                    category: "Wage and Hour Compliance",
                    details: [
                        "Ensuring compliance with laws on minimum wages, overtime, and working hours.",
                        "Advising on night shift policies and compensatory time off.",
                        "Resolving disputes over wage deductions or arrears."
                    ]
                },
                {
                    category: "Labour Inspections and Penalties",
                    details: [
                        "Assisting in preparation for labour inspections by government authorities.",
                        "Representing clients in disputes arising from inspections or penalties.",
                        "Advising on responses to notices or show cause orders."
                    ]
                },
                {
                    category: "Dispute Resolution and Litigation",
                    details: [
                        "Representing employers or employees in disputes before:",
                        "Labour Courts.",
                        "Industrial Tribunals.",
                        "High Courts and Supreme Court.",
                        "Handling arbitration and conciliation proceedings in industrial disputes."
                    ]
                },
                {
                    category: "Mergers, Acquisitions, and Restructuring",
                    details: [
                        "Advising on labour law implications in mergers, acquisitions, or restructuring.",
                        "Handling issues related to transfer of employees or retrenchment.",
                        "Assisting in due diligence for workforce-related compliance."
                    ]
                },
                {
                    category: "Social Security Laws",
                    details: [
                        "Advising on compliance with social security laws, including EPF, ESI, and pensions.",
                        "Resolving disputes over contributions or benefits."
                    ]
                },
                {
                    category: "Policy Formulation and Advisory",
                    details: [
                        "Drafting and advising on workplace policies, including:",
                        "Code of conduct.",
                        "Grievance redressal mechanisms.",
                        "Leave and attendance policies.",
                        "Advising on compliance with the Labour Codes (if applicable)."
                    ]
                },
                {
                    category: "Outsourcing and Contract Labour",
                    details: [
                        "Advising on compliance with the Contract Labour (Regulation and Abolition) Act.",
                        "Drafting agreements for outsourcing or manpower supply.",
                        "Handling disputes involving contract workers or agencies."
                    ]
                },
                {
                    category: "Foreign Nationals and Expatriate Employment",
                    details: [
                        "Advising on employment of expatriates or foreign nationals.",
                        "Assisting with work permits, visas, and compliance with local labour laws."
                    ]
                },
                {
                    category: "Grievance Redressal Mechanisms",
                    details: [
                        "Setting up and managing internal grievance redressal mechanisms.",
                        "Representing employers or employees in grievance resolution."
                    ]
                },
                {
                    category: "Advisory for Startups and MSMEs",
                    details: [
                        "Advising startups and MSMEs on labour law compliance.",
                        "Drafting employee handbooks and HR policies.",
                        "Ensuring compliance with simplified provisions under labour laws."
                    ]
                }
            ]
        }
        ,
        ,
        // { id: 9, title: "Arbitration and Conciliation Laws", description: "We help resolve disputes through arbitration and conciliation, offering faster and cost-effective alternatives to litigation.", image: arb },
        {
            id: 9,
            title: "Arbitration and Conciliation Laws",
            description: "Arbitration and conciliation services focus on resolving disputes outside the courts through alternative dispute resolution (ADR) mechanisms.",
            image: cl, // Replace 'acl' with the appropriate image variable or path
            services: [
                {
                    category: "Arbitration Services",
                    subcategories: [
                        {
                            title: "Domestic Arbitration",
                            details: [
                                "Advising on disputes under the Arbitration and Conciliation Act, 1996 (India) or equivalent laws in other jurisdictions.",
                                "Representing parties in domestic arbitration proceedings.",
                                "Drafting and negotiating arbitration agreements."
                            ]
                        },
                        {
                            title: "International Arbitration",
                            details: [
                                "Representing clients in international arbitration forums like:",
                                "International Chamber of Commerce (ICC).",
                                "London Court of International Arbitration (LCIA).",
                                "Singapore International Arbitration Centre (SIAC).",
                                "Permanent Court of Arbitration (PCA).",
                                "Advising on cross-border disputes and jurisdictional issues."
                            ]
                        },
                        {
                            title: "Institutional Arbitration",
                            details: [
                                "Representation before institutional arbitration bodies.",
                                "Assistance with procedural compliance under institutional rules."
                            ]
                        },
                        {
                            title: "Ad-Hoc Arbitration",
                            details: [
                                "Advising on procedural rules and legal frameworks for ad-hoc arbitration.",
                                "Drafting submissions and evidence presentation."
                            ]
                        }
                    ]
                },
                {
                    category: "Conciliation Services",
                    details: [
                        "Representing clients in conciliation proceedings under relevant laws.",
                        "Assisting in reaching mutually acceptable solutions through structured negotiations.",
                        "Drafting and formalizing settlement agreements."
                    ]
                },
                {
                    category: "Drafting and Reviewing Agreements",
                    details: [
                        "Drafting arbitration clauses in contracts.",
                        "Reviewing and revising arbitration and conciliation clauses to ensure enforceability.",
                        "Advising on choice of law, seat of arbitration, and governing rules."
                    ]
                },
                {
                    category: "Enforcement of Arbitral Awards",
                    details: [
                        "Assisting in the enforcement of domestic and foreign arbitral awards.",
                        "Representing clients in execution proceedings under the New York Convention or domestic laws.",
                        "Challenging or defending arbitral awards in courts."
                    ]
                },
                {
                    category: "Interim Relief in Arbitration",
                    details: [
                        "Filing applications for interim measures, such as injunctions or asset freezing, before arbitration.",
                        "Advising on emergency arbitration mechanisms."
                    ]
                },
                {
                    category: "Arbitration-Related Litigation",
                    details: [
                        "Representation in matters related to:",
                        "Appointment of arbitrators.",
                        "Challenging the validity of arbitration agreements.",
                        "Setting aside arbitral awards.",
                        "Handling disputes over the jurisdiction of arbitral tribunals."
                    ]
                },
                {
                    category: "Mediation Services (Hybrid ADR Mechanisms)",
                    details: [
                        "Assisting in hybrid ADR processes like Med-Arb (Mediation-Arbitration).",
                        "Representing clients in mediation as a precursor to arbitration."
                    ]
                },
                {
                    category: "Industry-Specific Arbitration",
                    details: [
                        "Specialized arbitration in areas such as:",
                        "Construction and infrastructure disputes.",
                        "Intellectual property disputes.",
                        "Maritime and shipping disputes.",
                        "Energy, oil, and gas sector disputes.",
                        "Technology and software disputes."
                    ]
                },
                {
                    category: "Advisory for Arbitration Agreements",
                    details: [
                        "Advising businesses on incorporating arbitration clauses into contracts.",
                        "Drafting multi-tier dispute resolution mechanisms (e.g., negotiation, mediation, and arbitration)."
                    ]
                },
                {
                    category: "Training and Workshops",
                    details: [
                        "Conducting training sessions on arbitration and conciliation for corporate clients.",
                        "Guiding in-house legal teams on ADR mechanisms and best practices."
                    ]
                },
                {
                    category: "Arbitrator and Conciliator Services",
                    details: [
                        "Acting as arbitrators or conciliators in disputes.",
                        "Assisting parties in the selection of neutral arbitrators or conciliators."
                    ]
                },
                {
                    category: "Recognition of Foreign Arbitral Awards",
                    details: [
                        "Advising on the recognition and enforcement of foreign arbitral awards under international treaties like:",
                        "The New York Convention.",
                        "The Geneva Convention.",
                        "Handling objections to recognition or enforcement of foreign awards."
                    ]
                },
                {
                    category: "Expedited Arbitration",
                    details: [
                        "Assisting with fast-track arbitration proceedings for quicker resolution.",
                        "Advising on simplified rules for expedited arbitration."
                    ]
                },
                {
                    category: "Online Dispute Resolution (ODR)",
                    details: [
                        "Representing clients in virtual arbitration or conciliation proceedings.",
                        "Advising on platforms and tools for effective ODR."
                    ]
                },
                {
                    category: "Pre-Arbitration Strategy and Counseling",
                    details: [
                        "Assessing the suitability of arbitration or conciliation for a dispute.",
                        "Preparing pre-arbitration notices and responses.",
                        "Strategic advice on cost, timeline, and enforceability considerations."
                    ]
                },
                {
                    category: "Settlement Negotiations",
                    details: [
                        "Assisting parties in negotiations during arbitration or conciliation.",
                        "Drafting and formalizing terms of settlement agreements."
                    ]
                }
            ]
        },
        // { id: 10, title: "MATRIMONIAL LAW", description: "We help resolve disputes through arbitration and conciliation, offering faster and cost-effective alternatives to litigation.", image: arb },
        {
            id: 10,
            title: "Matrimonial",
            description: "We help resolve disputes through arbitration and conciliation, offering faster and cost-effective alternatives  to litigation.",
            image: arb,
            services: [
                {
                    category: "Marriage Registration and Legalization",
                    details: [
                        "Assistance with marriage registration under relevant laws (e.g., Special Marriage Act or Hindu Marriage Act in India).",
                        "Guidance on interfaith and intercaste marriages.",
                        "Validating foreign marriages in local jurisdictions."
                    ]
                },
                {
                    category: "Divorce and Separation",
                    details: [
                        "Filing for contested or mutual divorce.",
                        "Legal representation in annulment cases.",
                        "Drafting and negotiating separation agreements.",
                        "Handling desertion cases."
                    ]
                },
                {
                    category: "Maintenance and Alimony",
                    details: [
                        "Claims for spousal maintenance or alimony during and after divorce.",
                        "Responding to or challenging maintenance claims.",
                        "Legal advice on temporary or permanent alimony."
                    ]
                },
                {
                    category: "Child Custody and Support",
                    details: [
                        "Securing legal custody or joint custody of children.",
                        "Establishing or contesting child visitation rights.",
                        "Filing for child support and enforcement of support orders."
                    ]
                },
                {
                    category: "Property and Asset Division",
                    details: [
                        "Legal assistance in dividing marital or jointly owned property.",
                        "Advising on settlement agreements.",
                        "Handling disputes over ancestral or inherited property in matrimonial cases."
                    ]
                },
                {
                    category: "Domestic Violence and Abuse",
                    details: [
                        "Filing or defending cases under domestic violence laws.",
                        "Obtaining protection orders or restraining orders.",
                        "Legal support for emotional, physical, or financial abuse."
                    ]
                },
                {
                    category: "Dowry and Related Issues",
                    details: [
                        "Handling disputes related to dowry demands or harassment.",
                        "Filing complaints under anti-dowry laws.",
                        "Advising on the recovery of stridhan (women’s property)."
                    ]
                },
                {
                    category: "Pre-Marital and Post-Marital Agreements",
                    details: [
                        "Drafting and reviewing prenuptial agreements.",
                        "Advising on postnuptial agreements related to property, finances, or other terms."
                    ]
                },
                {
                    category: "Second Marriage and Bigamy Issues",
                    details: [
                        "Legal guidance for second marriages after divorce.",
                        "Handling cases of bigamy or polygamy."
                    ]
                },
                {
                    category: "Live-In Relationship Legalities",
                    details: [
                        "Advising on the rights of partners in live-in relationships.",
                        "Handling disputes over property, maintenance, or child custody."
                    ]
                },
                {
                    category: "Interfaith and Intercaste Marriages",
                    details: [
                        "Assisting with legal formalities under the Special Marriage Act.",
                        "Protecting rights in case of opposition from families or communities."
                    ]
                },
                {
                    category: "Matrimonial Fraud",
                    details: [
                        "Handling cases involving fraudulent marriages.",
                        "Representing clients in cases of identity fraud or misrepresentation in marriage."
                    ]
                },
                {
                    category: "Surrogacy and Adoption",
                    details: [
                        "Legal guidance on surrogacy arrangements.",
                        "Assistance with adoption procedures and related disputes."
                    ]
                },
                {
                    category: "Mediation and Counseling",
                    details: [
                        "Offering mediation services to resolve marital disputes amicably.",
                        "Coordinating with professional counselors or mediators for reconciliation."
                    ]
                },
                {
                    category: "NRI and Cross-Border Matrimonial Cases",
                    details: [
                        "Handling divorce, custody, or maintenance disputes involving Non-Resident Indians (NRIs).",
                        "Dealing with international child abduction or custody cases.",
                        "Cross-border enforcement of matrimonial orders."
                    ]
                }
            ]
        }
        ,

        // { id: 11, title: "IMMIGRATION LAW", description: "We help resolve disputes through arbitration and conciliation, offering faster and cost-effective alternatives to litigation.", image: arb },
        {
            id: 11,
            title: "Immigration Law",
            description: "Expert legal services for all aspects of immigration, including visas, permanent residency, citizenship, business immigration, and more.",
            image: arb, // Replace 'immigration_law' with the appropriate image variable or path
            services: [
                {
                    category: "Visa Applications",
                    details: [
                        "Work Visas: H-1B, L-1, E-2, and other employment-based visas.",
                        "Study Visas: F-1, J-1, M-1, and student-related permits.",
                        "Visitor Visas: Tourist and business visas (B-1/B-2).",
                        "Investor Visas: Assistance with investor programs like EB-5.",
                        "Family Visas: K-1 fiancé(e) visas, spouse visas, and dependent visas."
                    ]
                },
                {
                    category: "Permanent Residency",
                    details: [
                        "Green Card Applications:",
                        "• Family-sponsored green cards.",
                        "• Employment-based green cards.",
                        "• Diversity visa lottery.",
                        "Adjustment of status for individuals already residing in the country."
                    ]
                },
                {
                    category: "Citizenship and Naturalization",
                    details: [
                        "Preparing and filing applications for citizenship.",
                        "Guidance on naturalization eligibility and requirements.",
                        "Assistance with language and civics test preparation.",
                        "Dual citizenship consultation."
                    ]
                },
                {
                    category: "Business Immigration",
                    details: [
                        "Employment-based immigration petitions (EB-1, EB-2, EB-3 categories).",
                        "Employer compliance with immigration laws (I-9 verification).",
                        "Assisting companies with sponsoring employees for visas.",
                        "Advising on global mobility and relocation."
                    ]
                },
                {
                    category: "Refugee and Asylum Applications",
                    details: [
                        "Filing applications for asylum seekers.",
                        "Representation in asylum interviews and hearings.",
                        "Refugee status applications for individuals fleeing persecution."
                    ]
                },
                {
                    category: "Deportation and Removal Defense",
                    details: [
                        "Representation in deportation or removal proceedings.",
                        "Filing for cancellation of removal.",
                        "Assistance with voluntary departure or relief applications.",
                        "Appeals against deportation orders."
                    ]
                },
                {
                    category: "Appeals and Waivers",
                    details: [
                        "Appeals for denied visas or green cards.",
                        "Filing waivers for inadmissibility (e.g., criminal records, medical issues).",
                        "Assisting with motions to reopen or reconsider cases."
                    ]
                },
                {
                    category: "Temporary Protected Status (TPS)",
                    details: [
                        "Applications for individuals from countries affected by conflict or natural disasters.",
                        "Extensions and renewals of TPS."
                    ]
                },
                {
                    category: "Immigration Compliance for Employers",
                    details: [
                        "Audits of employee documentation and compliance with laws.",
                        "Training and policy development to avoid penalties.",
                        "Representation in cases of non-compliance."
                    ]
                },
                {
                    category: "Family Reunification",
                    details: [
                        "Petitioning for family members to join or remain with immigrants.",
                        "Handling adoption-related immigration cases.",
                        "Advising on inter-country marriage and related processes."
                    ]
                },
                {
                    category: "Legal Representation",
                    details: [
                        "Representation in immigration court.",
                        "Advocacy in administrative hearings.",
                        "Assistance with interviews at embassies or consulates."
                    ]
                },
                {
                    category: "Special Immigration Programs",
                    details: [
                        "DACA (Deferred Action for Childhood Arrivals) applications and renewals.",
                        "U visas for victims of crimes.",
                        "T visas for victims of human trafficking.",
                        "VAWA (Violence Against Women Act) petitions."
                    ]
                },
                {
                    category: "Global Immigration Services",
                    details: [
                        "Assistance with immigration to countries other than the U.S.",
                        "Work and residency permits for international jurisdictions."
                    ]
                },
                {
                    category: "Document Assistance",
                    details: [
                        "Preparation of affidavits, declarations, and supporting documentation.",
                        "Translation and notarization of foreign documents.",
                        "Assistance with retrieving lost or misplaced immigration documents."
                    ]
                },
                {
                    category: "Immigration Policy Advice",
                    details: [
                        "Guidance on changes in immigration laws and regulations.",
                        "Strategic planning for individuals or businesses affected by new policies."
                    ]
                }
            ]
        }
        ,
        ,
        {
            id: 12,
            title: "Criminal Services",
            description: "Comprehensive legal services for defending individuals accused of crimes, ensuring fair trials, and offering post-conviction support.",
            image: cl, // Replace 'criminal' with the appropriate image variable or path
            services: [
                {
                    category: "Legal Representation",
                    details: [
                        "Defending individuals accused of crimes during trials.",
                        "Representing clients in bail hearings.",
                        "Appealing convictions or sentences in higher courts."
                    ]
                },
                {
                    category: "Pre-Trial Services",
                    details: [
                        "Advising clients during police investigations and interrogations.",
                        "Assistance with pre-charge consultations.",
                        "Securing anticipatory bail or interim relief."
                    ]
                },
                {
                    category: "Legal Advice and Consultation",
                    details: [
                        "Counselling clients on their rights and potential legal strategies.",
                        "Explaining the implications of criminal charges."
                    ]
                },
                {
                    category: "Drafting and Filing Legal Documents",
                    details: [
                        "Preparing applications for bail, parole, or probation.",
                        "Drafting appeals, revisions, and other legal documents.",
                        "Filing writ petitions for issues like illegal detention."
                    ]
                },
                {
                    category: "Defense in Various Criminal Offenses",
                    details: [
                        "White-collar crimes (fraud, embezzlement, insider trading).",
                        "Violent crimes (assault, murder, kidnapping).",
                        "Drug-related offenses.",
                        "Cybercrimes (online fraud, hacking).",
                        "Property crimes (theft, burglary, arson).",
                        "Sexual offenses (harassment, assault)."
                    ]
                },
                {
                    category: "Assistance with Regulatory Offenses",
                    details: [
                        "Defense in cases involving violations of local, state, or federal regulations.",
                        "Representation in cases of contempt of court."
                    ]
                },
                {
                    category: "Plea Bargaining",
                    details: [
                        "Negotiating with prosecutors to reduce charges or sentences."
                    ]
                },
                {
                    category: "Post-Conviction Assistance",
                    details: [
                        "Assisting with parole applications.",
                        "Filing for record expungement or sealing.",
                        "Addressing wrongful conviction claims."
                    ]
                },
                {
                    category: "Specialized Services",
                    details: [
                        "Juvenile criminal cases.",
                        "Domestic violence and related criminal matters.",
                        "Cross-border or extradition cases."
                    ]
                },
                {
                    category: "Advisory for Victims",
                    details: [
                        "Advising victims of crimes on their legal rights.",
                        "Filing criminal complaints (FIR) or private complaints.",
                        "Assistance in restitution or compensation claims."
                    ]
                }
            ]
        }
        ,
        {
            id: 13,
            title: "Taxation Law",
            services: [
                {
                    category: "Tax Compliance",
                    details: [
                        "Income Tax: Advising individuals and businesses on income tax filings.",
                        "Corporate Tax: Ensuring corporate tax compliance for companies.",
                        "GST (Goods and Services Tax): Assistance with GST registration, filing, and compliance.",
                        "Indirect Taxes: Advising on excise duty, customs duty, and VAT (where applicable).",
                        "Withholding Taxes (TDS/TCS): Ensuring proper deduction, collection, and filing of returns."
                    ]
                },
                {
                    category: "Tax Planning",
                    details: [
                        "Structuring investments to minimize tax liabilities.",
                        "Advising on tax-saving instruments and exemptions.",
                        "Estate and inheritance tax planning."
                    ]
                },
                {
                    category: "Tax Litigation and Dispute Resolution",
                    details: [
                        "Representation before tax authorities in assessment or scrutiny proceedings.",
                        "Appeals against tax assessments or penalties at appellate tribunals or courts.",
                        "Handling tax evasion investigations or prosecutions.",
                        "Settlement of disputes through alternative dispute resolution (ADR)."
                    ]
                },
                {
                    category: "International Taxation",
                    details: [
                        "Advising on cross-border transactions and double taxation issues.",
                        "Structuring investments to comply with international tax laws.",
                        "Assistance with treaties like Double Taxation Avoidance Agreements (DTAAs).",
                        "Transfer pricing documentation and compliance."
                    ]
                },
                {
                    category: "Corporate Tax Services",
                    details: [
                        "Advising on tax implications of mergers, acquisitions, and restructuring.",
                        "Assistance with dividend taxation and share transfers.",
                        "Compliance with Minimum Alternate Tax (MAT) and other corporate tax regulations."
                    ]
                },
                {
                    category: "Tax Advisory for Individuals",
                    details: [
                        "Guidance on tax implications for salaried employees, freelancers, and professionals.",
                        "Advice on capital gains from the sale of assets like property or shares.",
                        "Assistance with tax exemptions, rebates, and deductions."
                    ]
                },
                {
                    category: "Indirect Tax Advisory",
                    details: [
                        "Guidance on GST rates, input tax credit, and refunds.",
                        "Advising on customs duties for import/export transactions.",
                        "Representation in disputes related to GST, VAT, or other indirect taxes."
                    ]
                },
                {
                    category: "Tax Audits",
                    details: [
                        "Conducting tax audits for individuals, firms, or corporations.",
                        "Assistance during audits initiated by tax authorities."
                    ]
                },
                {
                    category: "Tax Incentives and Benefits",
                    details: [
                        "Identifying and availing government schemes and tax benefits.",
                        "Guidance on exemptions for startups, special economic zones (SEZs), and other entities."
                    ]
                },
                {
                    category: "Taxation for Non-Residents",
                    details: [
                        "Advising non-resident Indians (NRIs) on tax liabilities in India.",
                        "Assistance with tax implications of income earned abroad.",
                        "Structuring transactions to minimize tax exposure for expatriates."
                    ]
                },
                {
                    category: "Tax Recovery and Refunds",
                    details: [
                        "Assistance with filing for tax refunds.",
                        "Resolving disputes related to delayed or denied refunds.",
                        "Representation in recovery proceedings initiated by tax authorities."
                    ]
                },
                {
                    category: "Taxation in Real Estate",
                    details: [
                        "Advising on stamp duty, registration charges, and capital gains tax.",
                        "Structuring real estate transactions to optimize tax liabilities."
                    ]
                },
                {
                    category: "Tax Policy and Legislative Advice",
                    details: [
                        "Advising organizations on the impact of new tax laws or amendments.",
                        "Representation in public consultations on tax policy changes."
                    ]
                },
                {
                    category: "Non-Profit and Charitable Organizations",
                    details: [
                        "Assistance with obtaining tax-exempt status under relevant laws.",
                        "Advising on compliance for donations, grants, and other income."
                    ]
                },
                {
                    category: "Specialized Taxation Areas",
                    details: [
                        "Wealth Tax: Guidance on wealth tax implications (if applicable).",
                        "Inheritance and Estate Tax: Advising on estate distribution and tax liabilities.",
                        "Carbon Taxes: Assistance with taxes on environmental impact or sustainability measures."
                    ]
                }
            ]
        }
        ,

    ];
    // const law = lawCategories.find((law) => law.id === parseInt(id));

    // const category = lawCategories.find((item) => item.id === parseInt(id, 20)); // Find the matching category

    // if (!category) {
    //     return <h2>Category not found!</h2>;
    // }
    // Log the incoming data for debugging
    // console.log("id:", id);
    // console.log("lawCategories:", lawCategories);

    // Validate the ID (assuming valid IDs range from 1 to 20)
    const isValidId = !isNaN(id) && id > 0 && id <= 20;
    if (!isValidId) {
        // console.error("Invalid ID:", id);
        return <h2>Invalid ID!!!!</h2>;
    }

    // console.log("ID is valid, proceeding to find category...");

    // Parse the ID as an integer
    const parsedId = parseInt(id, 10);

    // Find the matching category
    const category = lawCategories.find(
        (item) => item && item.id === parsedId // Ensure item exists and matches the ID
    );

    if (!category) {
        // console.error("Category not found for ID:", parsedId);
        return <h2>Category not found!</h2>;
    }

    // console.log("Category found:", category);

    // If category is found, proceed to render it or do further processing
    // return <h2>Valid ID! Category found: {category.name}</h2>; // Example of rendering the category name


    return (
        <>
            <section className="details-pages-law position-relative">
                {category?.image && (
                    <img src={category.image} className="img-fluid w-100" alt={category.title || "Law Details"} />
                )}

                <div className="law-details position-absolute">
                    <h1>{category?.title || "Law Details"}</h1>
                    <hr />
                    <p>{category?.description || "No description available."}</p>


                </div>
            </section>

            <div class="container py-4">
                <Row>
                    <Col md={12}>
                        {/* Display all details in points */}
                        <div className="services">
                            {category?.services?.length ? (
                                category.services.map((service, index) => (
                                    <div key={index} className="service-category">
                                        <h3>{service.category || "Unnnamed Category"}</h3>
                                        <ul>
                                            {service.subcategories
                                                ? service.subcategories.map((sub, subIndex) => (
                                                    <li key={subIndex}>
                                                        <strong>{sub.title || "Unnamed Subcategory"}</strong>
                                                        <ul>
                                                            {sub.details?.map((detail, detailIndex) => (
                                                                <li key={detailIndex}>{detail || "No details available."}</li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))
                                                : service.details?.map((detail, detailIndex) => (
                                                    <li key={detailIndex}>{detail || "No details available."}</li>
                                                ))}
                                        </ul>
                                    </div>
                                ))
                            ) : (
                                <p>No services available.</p>
                            )}
                        </div>
                    </Col>
                </Row>
                {/* <div class="row">

                    <div class="col-12">
                        <ul class="list-group border-0">
                            <li class="list-group-item">Legal Help in Making Registration of FIR or Criminal Complaint, under 498A, 406, Domestic Violence Act, Dowry Prohibition Act, etc.</li>

                            <li class="list-group-item">Filing of Criminal Complaint Before Police Station or Magistrate.</li>
                            <li class="list-group-item">Filing and Arguing of Anticipatory Bail Applications Before Court of Session and High Courts.</li>
                            <li class="list-group-item">Filing of Regular Bail Before Criminal Courts.</li>
                            <li class="list-group-item">Filing of Revision, Appeal, Criminal Writ, Petition Against the Acquittal/Conviction.</li>
                            <li class="list-group-item">Criminal Trial Before Magistrate or Court of Session.</li>
                            <li class="list-group-item">Filing/Defending Complaint Under Section 138 of N.I. Act Before Magistrate.</li>
                            <li class="list-group-item">Filing/Defending The Criminal Complaint Under Various Provisions and Sections Of Indian Penal Code, Companies Act, Factories Act, Food Adulteration Act, Passport Act, NDPS Act, Arms Act, etc.</li>
                            <li class="list-group-item">Criminal Misappropriation Of Funds.</li>
                            <li class="list-group-item">Criminal Breach Of Trust and Criminal Conspiracy.</li>
                            <li class="list-group-item">Dishonour Of Cheque.</li>
                            <li class="list-group-item">Forgery and Mischief.</li>
                            <li class="list-group-item">Offence Related To Marriage Viz. (A) Offence Unusable Under Dowry Act (B) Domestic Violence Act (C) 498A, 406, or IPC Etc.</li>
                            <li class="list-group-item">Offence Related To Drugs Punishable Under NDPS Act.</li>
                            <li class="list-group-item">Sexual Offences.</li>
                            <li class="list-group-item">Offences Against The State.</li>
                            <li class="list-group-item">Offences Against The Public Tranquility And Public Justice.</li>
                            <li class="list-group-item">Offences Related To Weights And Measures, Public Health, Safety, Decency, And Morals.</li>
                            <li class="list-group-item">Offences Affecting Human Body Viz. Murder, Suicide, Dowry Death, Causing Death By Negligence, Rape, Kidnapping, Unnatural Sex, etc.</li>
                            <li class="list-group-item">Offence Related To Property Viz. Theft, Robbery, Extortion, Cheating, Breach Of Trust, Misappropriation Of Properties, etc.</li>
                            <li class="list-group-item">Offences Punishable Under The Code Of Indian Penal Or Bharatiya Nyay Sanhita.</li>
                            <li class="list-group-item">Various Economic Offences Punishable Under SEBI, FEMA, Passport Act, Immigration Act, etc.</li>
                        </ul>
                    </div>
                </div> */}
            </div>


        </>
    );
}

export default CriminalLawDetails
